import featureFlagEnabled from './featureFlagEnabled';

export default (featureFlag) => async (to, from, next) => {
  const enableProviderPayments = await featureFlagEnabled(featureFlag);

  if (!enableProviderPayments) {
    window.location.href = '/';
    return false;
  }

  return next();
};
