const states = {
  'added to waitlist': 'Child added to waitlist',
  'contacted to stay': 'Kinside reached out to the family to see if they are interested in staying on the waitlist',
  'deletion reminder': 'The family was on hold for more than 30 days and sent a final deletion reminder',
  enrolled: 'Child enrolled!',
  migrated: 'The child aged out of one waitlist and onto another waitlist',
  'no response': 'The family did not respond to their seat offer and were put on hold',
  'no response after inactive': 'The family did not respond to a final warning after being on hold more than 30 days and were deleted. If you believe this is in error, contact support@kinside.com to reactivate',
  'parent confirmed': 'The family confirmed they want to stay on the waitlist',
  'parent declined': 'The family passed on a seat offer and removed themselves from your waitlist',
  'parent passed': 'The family passed on the offer but stayed on your waitlist',
  'parent question': 'The family asked a question about the seat offer',
  'parent requested removal': 'The family requested to be removed from the waitlist',
  'question answered': 'You answered the family\'s question',
  'ready to enroll': 'The family indicated they are ready to enroll',
  'return to waitlist': 'A family that had enrolled in a seat returned to the waitlist',
  'schedule tour': 'The family requested a tour',
  'seat offered': 'A seat was offered',
  'seat removed': 'The seat which had been offered to this family was deleted',
  'sent paperwork': 'Registration Form was sent to the family',
  'spot reactivated': 'The family was on hold and reactivated their waitlist spot',
  'tour scheduled': 'You scheduled an enrollment tour',
  'xf-parent question': 'The family claimed a seat from your enrollment page and asked a question',
  'xf-tour scheduled': 'The family claimed a seat from your enrollment page and scheduled an enrollment tour',
  'xf-ready to enroll': 'The family claimed a seat from your enrollment page and is ready to enroll',
  'xf-sent paperwork': 'The family claimed a seat from your enrollment page and is ready to enroll',
  'xf-question answered': 'The family claimed a seat from your enrollment page and asked a question',
  'xp-parent question': 'You offered this seat directly and indicated the family had a question',
  'xp-tour scheduled': 'You offered this seat directly and scheduled a tour',
  'xp-ready to enroll': 'You offered this seat directly and sent registration paperwork',
  'xp-sent paperwork': 'You offered this seat directly and send registration paperwork',
  'xp-question answered': 'You offered this seat directly and indicated the family had a question',
  offerRevoked: 'You revoked the spot offer',
  enrollment_withdrawn: 'Enrollment offer withdrawn:',
  enrollment_withdrawn_to_waitlist: 'Enrollment offer withdrawn:',
  unenrolled: 'Child un-enrolled',
};

export default (state) => (states[state] || state);
