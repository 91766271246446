import moment from 'moment-timezone';
import { getDollarFormat } from './dollarFormat';

const escapeCommas = (value) => {
  if (typeof value === 'string' && value.includes(',')) {
    return `"${value}"`;
  }
  return value;
};

const createCSV = (data) => {
  const rows = data.map((row) => row.map(escapeCommas).join(','));

  return rows.join('\n');
};

export default (name, paymentRequests) => {
  const csv = [
    ['Date', 'Amount', 'Type', 'Child', 'Status', 'Invoice UID'],
  ];
  paymentRequests.forEach((row) => {
    const rowData = [
      row.scehduledAt || row.createdAt,
      getDollarFormat(row.amount),
      row.category,
      // eslint-disable-next-line
      row.childrenNames?.join('\, '),
      row.aasmStateName,
      row.invoiceUid,
    ];

    csv.push(rowData);
  });

  const anchor = document.createElement('a');
  anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(createCSV(csv))}`;
  anchor.target = '_blank';
  anchor.download = `${name}-payments-${moment().format('YYYY-MM-DD')}.csv`;
  anchor.click();
};
